import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"

const KebsOverview = () => {
  return (
    <section className="case-study cs-overview">
        <Container>
            <Row className="justify-content-center">
                <Col lg={9}>
                    <div className="sub-content">
                        <p>When the Kaar Tech team approached us with a requirement for an explainer video for their dream product KEBS, we knew we had huge scope for some exploration.</p>
                        <p>To get the ball rolling, we needed an in-depth understanding of the product, how it works, the benefits they have in store, and what they are looking to convey through the video. The Kaar Tech team filled up our detailed questionnaire containing questions that would help us gain a better understanding of every aspect of the project. We also had briefing calls that further gave us a birds-eye view of their expectations.</p>
                    </div>
                    <StaticImage src="../../../images/case-studies/kebs/objectives.png" alt="KEBS Explainer Video Case Study | WowMakers" placeholder="blurred" className="img-fluid" width={800} height={450}/>
                </Col>
            </Row>
        </Container>
    </section>
        
  )
}

export default KebsOverview
