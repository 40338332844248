import React from "react"
import SeoHead from "../../components/global/seoHead"
import Layout from "../../components/global/layout"
import Seo from "../../components/global/seo"

import ScrollIndicationHeightZero from "../../components/global/scrollIndicationHeightZero"
import KebsMain from "../../components/case-studies/kebs/kebsMain"
import KebsInfo from "../../components/case-studies/kebs/kebsInfo"
import KebsOverview from "../../components/case-studies/kebs/kebsOverview"
import KebsUnderStandingProject from "../../components/case-studies/kebs/kebsUnderStandingProject"
import KebsBuildingStoryLine from "../../components/case-studies/kebs/kebsBuildingStoryLine"
import KebsPlanning from "../../components/case-studies/kebs/kebsPlanning"
import KebsGettingFrames from "../../components/case-studies/kebs/kebsGettingFrames"
import KebsMoreCs from "../../components/case-studies/kebs/kebsMoreCs"
import DirectContact from "../../components/global/directContact"

import csKebsFeaturedImage from "../../../static/case-studies/kebs/kebs-fetured-image.png"

import "../../sass/pages/case-studies/cs-global.scss"
import "../../sass/pages/case-studies/kebs.scss"

export const Head = () => (
  <>
    <SeoHead
      ogImage={csKebsFeaturedImage}
      title="KEBS Case Study | 2D Animation Explainer Video | Technology"
      description="An inside look at how we created an all-in-one business suite that helps teams communicate, collaborate, and connect with other teams."
    />
  </> 
)

const Kebs = () => {
    return (
      <Layout>
        <Seo
          bid="cs-kebs"
          bclass="case-studies-page"
        ></Seo>
        <ScrollIndicationHeightZero />
        <KebsMain />
        <KebsInfo />
        <KebsOverview />
        <KebsUnderStandingProject />
        <KebsBuildingStoryLine />
        <KebsPlanning />
        <KebsGettingFrames />
        <KebsMoreCs />
        <DirectContact />
      </Layout>
    )
}
export default Kebs
