import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import KebsPlanningVisuals1 from "../../../images/case-studies/kebs/planning-the-visuals-video-1.mp4"
import KebsPlanningVisuals2 from "../../../images/case-studies/kebs/planning-the-visuals-video-2.mp4"
import { Col, Container, Row } from "react-bootstrap"

const KebsPlanning = () => {
  return (
    <section className="case-study cs-planning">
        <Container>
            <Row className="justify-content-center">
                <Col lg={9}>
                    <h2 className="font-weight-bold text-center">Planning the visuals</h2>
                    <div className="sub-content mt-5">
                        <p>The client approved the script, and we went ahead with storyboarding which is a pictorial representation of the script that would help the client visualize the flow of the video in a better way. At this stage, we planned what the frames should look like. As the video was meant to target startups and SMEs at the same time, the frames had to have a perfect balance. We planned the character designs in such a way that while they looked unique and different, they did not look too formal or too casual, but somewhere in the middle so that the video would cater to both levels of business in terms of connecting with the characters.</p>
                    </div>
                    <StaticImage src="../../../images/case-studies/kebs/planning-the-visuals.png" alt="KEBS Explainer Video Case Study | WowMakers" placeholder="blurred" className="img-fluid" width={800} height={450}/>
                    <StaticImage src="../../../images/case-studies/kebs/planning-the-visuals-1.png" alt="KEBS Explainer Video Case Study | WowMakers" placeholder="blurred" className="img-fluid mt-5" width={800} height={450}/>
                    <div className="sub-content mt-5">
                        <p>The visuals were planned in such a way that they enhanced the message delivery in an easily understandable and connectable way, rather than just serving as mere representations. One such example is that we decided to go for an Isometric style to show the departments getting fragmented, and then coming together after the introduction of KEBS. This helped the viewers get a birds-eye view of about KEBS can bring the fragmented departments together.</p>
                    </div>
                    <Row>
                        <Col md={6}>
                            <video width="1196" height="674" className="vid" autoPlay={true} loop muted autoplay playsinline>
                                <source src={KebsPlanningVisuals1} type="video/mp4" />
                            </video>
                        </Col>
                        <Col md={6}>
                            <video width="1196" height="674" className="vid" autoPlay={true} loop muted autoplay playsinline>
                                <source src={KebsPlanningVisuals2} type="video/mp4" />
                            </video>                            
                        </Col>                        
                    </Row>
                    <div className="sub-content mt-5">
                        <p>Following this, we started with the Illustration phase where the rough sketches took form and transformed into neatly drawn frames. As the concept focused on how adversely a fragmented workflow was affecting the employees, the Illustrators made sure to visually portray the emotions that the employees went through - confused, frustrated, burnt out, and then relieved and happy when things fall in place for them through KEBS. The end result was a bunch of style frames that portrayed the vibrancy and hustle and bustle of office space, along with the reaction of the employees to certain situations.</p>
                    </div>
                    <StaticImage src="../../../images/case-studies/kebs/planning-the-visuals-2.png" alt="KEBS Explainer Video Case Study | WowMakers" placeholder="blurred" className="img-fluid" width={800} height={450}/>
                    <StaticImage src="../../../images/case-studies/kebs/planning-the-visuals-3.png" alt="KEBS Explainer Video Case Study | WowMakers" placeholder="blurred" className="img-fluid mt-5" width={800} height={450}/>                                                            
                </Col>
            </Row>
        </Container>
    </section>
        
  )
}

export default KebsPlanning
