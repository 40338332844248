import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import KebsGettingFramesMotion from "../../../images/case-studies/kebs/getting-the-frames-in-motion-video.mp4"
import { Col, Container, Row } from "react-bootstrap"

const KebsGettingFrames = () => {
  return (
    <section className="case-study cs-getting-frames">
        <Container>
            <Row className="justify-content-center">
                <Col lg={9}>
                    <h2 className="font-weight-bold text-center">Getting the frames in motion</h2>
                    <div className="sub-content mt-5">
                        <p>Now that the style frames were ready, our Animators put their heart and soul into making the video an exciting one. The way the style frames would get animated would make a big difference, and they did their best in breathing life into the still frames.</p>
                    </div>
                    <video width="1196" height="674" className="vid" autoPlay={true} loop muted autoplay playsinline>
                        <source src={KebsGettingFramesMotion} type="video/mp4" />
                    </video>
                    <div className="sub-content mt-5">
                        <p>The video now needed an attractive voiceover that would leverage its beauty. We picked a voiceover artist who we believed would deliver the right intonation and accent that deemed fit with the client's requirements. To make the video more captivating, a piece of appealing music and SFX effects were added to the background.</p>
                    </div>
                    <StaticImage src="../../../images/case-studies/kebs/getting-the-frames-in-motion.png" alt="KEBS Explainer Video Case Study | WowMakers" placeholder="blurred" className="img-fluid" width={800} height={450}/>
                    <div className="sub-content mt-5">
                        <h3>Results</h3>
                        <p>The end result was a compelling video that looked and sounded good, and comprehensive in terms of delivering the intended message. The video was successful in garnering client satisfaction as it helped them launch their product and spread the word effectively. The project gave us ample scope to experiment and give our best while adding one more amazing video to the list of our best works.</p>
                    </div>
                </Col>
            </Row>
        </Container>
    </section>
        
  )
}

export default KebsGettingFrames
