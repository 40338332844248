import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"

const KebsBuildingStoryLine = () => {
  return (
    <section className="case-study cs-building-story-line">
        <Container>
            <Row className="justify-content-center">
                <Col lg={9}>
                    <h2 className="font-weight-bold text-center">Building a strong storyline</h2>
                    <div className="sub-content mt-5">
                        <p>Once we had a clear understanding of their requirements and their product, we jumped head-first into crafting a few strong concepts that would help us convey the message in a comprehensive way.</p>
                        <p>We thought a problem-solution approach would be well suited for the video and we put together a storyline that went along the same line. One of the concepts we delivered narrated the story of a CXO-level executive who is trying to scale the business and a bunch of frustrated employees who are finding it difficult to have a seamless workflow due to the fragmentation of work.</p>
                        <p>Once the problem was established, the concept further introduced KEBS as a solution that can bring together all departments on the same page, thereby helping the employees have an uninterrupted workflow. The client preferred this approach and gave us a go, as it aligned with what they wanted to convey. We then started with the scripting process.</p>
                        <p>The concept was broken down into visuals and a voiceover, so as to bring more structure to the story. The voiceover was written maintaining a conversational tone, by breaking down how KEBS makes work easy for businesses. We made sure to incorporate the client feedback in every stage of the project so that we create something that is aligned with their vision.</p>
                    </div>
                    <StaticImage src="../../../images/case-studies/kebs/building-a-strong-storyline.png" alt="KEBS Explainer Video Case Study | WowMakers" placeholder="blurred" className="img-fluid" width={800} height={450}/>
                </Col>
            </Row>
        </Container>
    </section>
        
  )
}

export default KebsBuildingStoryLine
