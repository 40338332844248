import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"

const KebsUnderStandingProject = () => {
  return (
    <section className="case-study cs-usp">
        <Container>
            <Row className="justify-content-center">
                <Col lg={9}>
                    <h2 className="font-weight-bold text-center">Understanding the project</h2>
                    <div className="sub-content mt-5">
                        <p>Now, some context about the project and the product - The main objective for the video was to create awareness about KEBS, how it works and urge the audience to choose KEBS to build their business. KEBS, short for Kaar Enterprise Business Suite, is a work OS that manages all the business operations in a single platform. KEBS focuses on managing the end-to-end business operation seamlessly by bringing fragmented work into one software, with templates made for all departments to quickly adapt and execute. On that note, the video targets startups and small and medium-sized businesses.</p>
                    </div>
                    <StaticImage src="../../../images/case-studies/kebs/understanding-the-project.png" alt="KEBS Explainer Video Case Study | WowMakers" placeholder="blurred" className="img-fluid" width={800} height={450}/>
                </Col>
            </Row>
        </Container>
    </section>
        
  )
}

export default KebsUnderStandingProject
